'use client'

import { usePathname } from 'lib/navigation'
import React from 'react'
import { useBoundStore } from 'store/useBoundStore'

const AppLayoutMenu = ({ children }: { children: React.ReactNode }) => {
  const session = useBoundStore(state => state.session)
  const pathname = usePathname()

  if (
    session.guest &&
    (pathname.includes('/login') || pathname.includes('/signup'))
  ) {
    return null
  }

  return <>{children}</>
}

export default AppLayoutMenu
