import { getAccountCredits } from 'api/purchase'
import { clientFetch } from 'lib/fetch/client'
import { create } from 'zustand'

export type CreditState = {
  credit: number
}

export type CreditActions = {
  syncCredit: () => void
}

export type CreditStore = CreditState & CreditActions

export const useEnterpriseCreditStore = create<CreditStore>(set => ({
  credit: 0,
  syncCredit: () => {
    let timer: NodeJS.Timeout
    let canceled = false

    async function fetchQuota() {
      const { data } = await getAccountCredits(clientFetch).catch(() => ({
        data: null,
      }))
      if (canceled) {
        return
      }
      if (data) {
        set({ credit: data.balance })
      }

      timer = setTimeout(fetchQuota, 10000)
    }

    fetchQuota()

    return () => {
      clearTimeout(timer)
      canceled = true
    }
  },
}))
