'use client'
import React from 'react'

export type UserType = 'social' | 'enterprise'

const UserTypeContext = React.createContext<UserType>('social')

export const UserTypeProvider = ({
  children,
  type,
}: {
  children: React.ReactNode
  type: UserType
}) => {
  return (
    <UserTypeContext.Provider value={type}>{children}</UserTypeContext.Provider>
  )
}

export const useUserType = () => {
  const context = React.use(UserTypeContext)
  if (context === undefined) {
    throw new Error('useUserType must be used within a UserTypeProvider')
  }
  return context
}
