import { API } from '@paladise/config/api-url'
import type { Fetch } from 'lib/fetch/type'
import type { IResp } from 'type/common'

export const getPurchasesVerifyBindingV2 = async (
  fetcher: Fetch,
  subscriptionIds: string[],
): Promise<
  IResp<{
    purchases?: {
      group_id: string
      option_id: string
      purchase_id: string
    }[]
  }>
> => {
  const res = await fetcher(
    API.HERMES +
      `/purchases/verify_binding?purchase_ids=${subscriptionIds.join(',')}`,
    {
      method: 'GET',
    },
  )

  return res
}

export const getPurchases = async (
  fetcher: Fetch,
): Promise<
  IResp<{
    purchases: {
      avatar_id: string
      group_id: string
      option_id: string
      purchase_id: string
    }[]
  }>
> => {
  const res = await fetcher(API.HERMES + '/purchases', {
    method: 'GET',
  })

  return res
}

export const getAccountCredits = async (
  fetcher: Fetch,
): Promise<IResp<{ account_id: string; balance: number }>> => {
  const res = await fetcher(API.HERMES + '/credit/account', {
    method: 'GET',
  })

  return res
}
