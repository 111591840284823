'use client'

import React from 'react'
import { useEnterpriseCreditStore } from 'store/enterprise-credit-store'

const SyncEnterpriseCredit = ({ initialCredit }: { initialCredit: number }) => {
  const ref = React.useRef(false)

  if (!ref.current) {
    useEnterpriseCreditStore.setState({ credit: initialCredit })
    ref.current = true
  }

  React.useEffect(() => {
    return useEnterpriseCreditStore.getState().syncCredit()
  }, [])

  return null
}

export default SyncEnterpriseCredit
